.large-member-card {
    background-color: var(--accent-black);
    border-radius: 45px;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    width: 500px;
    overflow: hidden;
}

.large-member-card .card-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
    padding-bottom: 20px;
    gap: 10px;
    flex-grow: 1;
}

.large-member-card .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.large-member-card .logo-wrapper img {
    border-radius: 1000px;
    height: 10vmax;
    box-shadow: var(--accent-black) 0 0 30px 5px;
    object-fit: cover;
}

.large-member-card .card-header .text {
    width: 100%;
    padding-right: 35px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.large-member-card .card-header .text .pseudo {
    font-size: 20px;
    font-weight: 700;
    color: var(--accent-black);
}

.large-member-card .card-header .text .realname {
    font-size: 16px;
    font-weight: 200;
    color: var(--accent-black);
}

.large-member-card .card-header .social-medias div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.large-member-card .card-header .social-medias a {
    font-size: 2rem;
    display: block;
    transition: .2s;
}

.large-member-card .card-header .social-medias a * {
    color: var(--accent-black);
}

.large-member-card .card-header .social-medias a:hover {
    z-index: 100;
    transform: scale(1.2);
}

.large-member-card .card-body {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.large-member-card .card-body .short-description {
    font-weight: 700;
    max-width: 75%;
    font-size: 14px;
    text-align: center;
    background-image: linear-gradient(to right bottom, var(--accent-red), var(--normal-black));
    border-radius: 1000px;
    padding: 15px;
}

.large-member-card .card-body .long-description {
    position: relative;
    max-width: 75%;
    font-size: 16px;
    background-color: var(--normal-black);
    border-radius: 25px;
    padding: 2rem;
    text-align: justify;
}

.large-member-card .card-body .long-description::first-letter {
    margin-left: 30px;
}

.large-member-card .card-body .long-description::before,
.large-member-card .card-body .long-description::after {
    position: absolute;
    display: block;
    width: 3rem;
    height: 4rem;
    font-size: 8rem;
    z-index: 20;
    font-family: "Helvetica Neue", sans-serif;
}

.large-member-card .card-body .long-description::before {
    top: -2.5rem;
    left: 0.5rem;
    content: open-quote;
}

.large-member-card .card-body .long-description::after {
    bottom: -1rem;
    right: 0.5rem;
    content: close-quote;
}

@media screen and (max-width: 1000px) {
    .large-member-card {
        width: 100%;
    }

    .large-member-card .logo-wrapper img {
        height: 20vmax;
    }
}

.large-member-card .round-button {
    font-size: 1rem;
}