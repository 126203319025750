.round-button {
    display: flex;
    color: var(--accent-black);
    background-color: var(--normal-white);
    border-radius: 1000px;
    font-weight: 700;
    padding: 0.5em 1em;
    width: max-content;
    font-size: 1.5rem;
    transition: .2s;
    gap: 10px;
}

.round-button svg {
    font-size: 2rem;
}

.round-button:hover {
    z-index: 100;
    transform: scale(1.2);
}