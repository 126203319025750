.footer {
    color: var(--normal-white);
    padding: 100px 50px;
    margin-top: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    background-color: var(--accent-black);
}

.footer .columns {
    display: flex;
    gap: 30px;
}

@media only screen and (max-width: 1000px) {
    .footer .columns {
        gap: 30px;
        flex-direction: column;
        align-items: center;
    }
}

.footer .bottom {
    text-align: center;
    color: var(--deactivated-light);
}

.footer .section {
    display: block;
    min-width: 150px;
    text-align: center;
    margin-bottom: 30px;
}

.footer .section a {
    font-weight: 300;
    display: block;
    margin-bottom: 3px;
    text-decoration: none;
}

.footer .section .title {
    font-weight: 700;
    margin-bottom: 10px;
}

.footer .bottom .text {
    font-weight: 300;
    color: inherit;
    margin-bottom: 10px;
}