.header-member-card {
    background-color: var(--accent-black);
    border-radius: 45px;
    width: 100%;
    min-height: 600px;
    display: flex;
}

.header-member-card > * {
    max-width: 50%;
    min-width: 50%;
    min-height: 100%;
    max-height: 100%;
}

@media screen and (max-width: 1000px) {
    .header-member-card {
        flex-direction: column;
    }

    .header-member-card > * {
        min-width: 100%;
        max-width: 100%;
    }
}