.title-header .down-logo {
    height: 2vh;
    pointer-events: none;
}

.title-header .logo {
    width: 50vw;
    pointer-events: none;
    animation: drift-fadein-animation 1.5s ease-in-out 1 forwards;
    animation-delay: 1s;
    opacity: 0;
}

.title-header .animation {
    color: var(--normal-white);
    font-size: 4vh;
    position: absolute;
    bottom: 20px;
    line-height: 2.5vh;
    animation: fadein-animation 1.5s ease-in-out 1 forwards;
    animation-delay: 2s;
    opacity: 0;
    width: 100vw;
}

.title-header .down-logo-wrapper {
    animation: title-scroll-animation 3s infinite;
    opacity: 0.1;
}

.title-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: blur(5px);
    mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    animation: fadein-blur-animation 10s ease-in-out forwards;
    opacity: 0;
}

.title-header .down-logo-wrapper:nth-child(1) {
    animation-delay: 0s;
}

.title-header .down-logo-wrapper:nth-child(2) {
    animation-delay: 1s;
}

.title-header .down-logo-wrapper:nth-child(3) {
    animation-delay: 2s;
}

.title-header {
    background-color: var(--accent-black);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.title-header .logo-wrapper {
    align-items: center;
}

@media screen and (max-width: 1000px) {
    .title-header .logo {
        width: 90vw;
    }
}

@keyframes title-scroll-animation {
    25% {
        opacity: 1;
    }
    50% {
        opacity: 0.1;
    }
}

@keyframes drift-fadein-animation {
    0% {
        transform: translateY(-5vmin);
        opacity: 0;
    }

    100% {
        transform: translateY(0vmin);
        opacity: 1;
    }
}

@keyframes fadein-animation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadein-blur-animation {
    0% {
        filter: blur(0px);
        opacity: 0;
    }

    16% {
        filter: blur(0px);
        opacity: 1;
    }

    50% {
        filter: blur(0px);
        opacity: 1;
    }

    100% {
        filter: blur(5px);
        opacity: 1;
    }
}