.projects {
    display: grid;
    gap: 50px;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    justify-content: center;
    margin: 30px 0;
}

@media screen and (max-width: 1000px) {
    .projects {
        display: flex;
        flex-direction: column;
        flex-flow: wrap;
        gap: 50px;
        align-items: center;
        margin: 30px 0;
    }
}