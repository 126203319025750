:root {
    /* Normal */
    --normal-black: #16191c;
    --normal-white: #eee;

    /* Accent */
    --accent-black: black;
    --accent-red: #e51a1a;
    --accent-yellow: #e5b61a;
    --accent-blue: #1a94e5;

    /* Deactivated */
    --deactivated-light: #7f8285;
    --deactivated-dark: #4a4b4f;

    scroll-behavior: smooth;
    background-color: var(--normal-black);
    overflow-x: clip;
}

:any-link {
    text-decoration: none;
    color: currentColor;
}

::-webkit-scrollbar {
    background: var(--deactivated-dark);
    width: 0.8vw;
}

::-webkit-scrollbar-thumb {
    background: var(--deactivated-light);
    border-radius: 1000px;
}

::selection {
    background-color: var(--normal-white);
    color: var(--normal-black);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

b {
    font-weight: 900;
}