.features-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: stretch;
    justify-items: stretch;
    gap: 30px;
    margin: 20px 0;
}

.features-grid .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 15px;
    height: 200px;
    background-color: var(--normal-black);
    transition: 0.4s ease;
    overflow: hidden;
    gap: 10px;
}

.features-grid .card svg {
    height: 100px;
}

.features-grid .card .title {
    font-size: 2rem;
    text-wrap: wrap;
    text-align: center;
}

.features-grid .card .description {
    color: var(--normal-black);
    text-wrap: wrap;
    text-align: justify;
    font-size: 0.8em;
    overflow: hidden;
    height: 0;
}

.features-grid .card:hover .description {
    height: fit-content;
    overflow: visible;
}

.features-grid .card .logo-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
}

.features-grid .card .logo {
    position: absolute;
    visibility: visible;
    height: 100%;
    transition: 0s;
}

.features-grid .card .description .heart {
    color: var(--accent-red);
    font-size: 1.2em;
    align-self: center;
}

.features-grid .card .logo-color {
    position: absolute;
    visibility: hidden;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.features-grid .card:hover .logo {
    visibility: hidden;
}

.features-grid .card:hover .logo-color {
    visibility: visible;
}

.features-grid .card:hover {
    color: var(--accent-black);
    background-color: var(--normal-white);
    transform: scale(1.5);
    z-index: 50;
}

.features-grid .card:hover ::selection {
    background-color: var(--normal-black);
    color: var(--normal-white);
}

.features-grid #logo-card:hover {
    background-image: url("https://static.dwightstudio.fr/jarmemu/SPLASH_BACKGROUND.svg");
    background-size: cover;
    color: var(--normal-white);
}

.features-grid #logo-card:hover .description {
    color: var(--normal-white);
}

@media screen and (max-width: 1000px) {
    .features-grid {
        grid-template-columns: repeat(1, 1fr);
    }
    .features-grid .card:hover {
        transform: scale(1.2);
    }
}