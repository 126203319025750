.header {
    position: sticky;
    top: 0;
    width: 100%;
    padding: 0 1%;
    background-color: var(--accent-black);
    box-sizing: border-box;
    box-shadow: 0 3px 10px 1px var(--accent-black);
    z-index: 98;
    overflow: visible;
}

.header .nav .logo {
    width: 10vw;
    padding: 0 2vmin;
    display: block;
}

.header .menu .logo-wrapper {
    width: auto;
    display: flex;
    justify-content: center;
}

.header .nav, .header .social-medias {
    color: var(--normal-white);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.header .social-medias a {
    font-size: 2rem;
    margin: 0 1vmin;
    display: block;
    transition: .2s;
}

.header .social-medias a:hover {
    z-index: 100;
    transform: scale(1.2);
}

.header .menu {
    justify-content: space-between;
    display: flex;
}

.header .menu.open {
    visibility: visible;
    left: 0;
}

.header .nav > * {
    padding: 2vmin 0;
    font-weight: 300;
    text-decoration: none;
}

.header .nav > * > div {
    color: var(--normal-white);
    transition: .2s;
    padding: 5px 10px;
}

.header .nav > * > div:hover {
    z-index: 100;
    transform: scale(1.2);
}

.header .nav > .link-selected > div {
    color: var(--accent-black);
    background-color: var(--normal-white);
    border-radius: 1000px;
    font-weight: 700;
    max-width: max-content;
}

#hamburger {
    z-index: 98;
    visibility: hidden;
    width: 30px;
    height: 35px;
    position: absolute;
    left: 25px;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#hamburger div {
    width: 100%;
    height: 15%;
    margin: 3px 0;
    background-color: var(--normal-white);
    transition: .4s;
}

#hamburger.open div:nth-child(1) {
    width: 70%;
    transform: rotateZ(-45deg) translateX(-8px);
}

#hamburger.open div:nth-child(3) {
    width: 70%;
    transform: rotateZ(45deg) translateX(-8px);
}

.header .mobile {
    height: 0;
}

@media only screen and (max-width: 1000px) {
    #hamburger {
        visibility: visible;
    }

    .header .menu {
        background-color: var(--accent-black);
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 5vh;
        position: absolute;
        left: -100vw;
        top: 0;
        width: 60vw;
        height: 100vh;
        transition: .4s;
        flex-direction: column;
    }

    .header .menu > * > div {
        padding: 5px 10px;
        margin: 20px 0;
    }

    .header .menu .nav > * > div {
        margin: 2vmin 1vmin;
    }

    .header .mobile {
        height: 70px;
    }

    .header .menu .logo-wrapper {
        width: 100%;
    }

    .header .menu .logo {
        width: 50vw;
        max-height: 20vh;
        align-self: center;
    }

    .header .nav {
        display: block;
        font-size: 1.5rem;
        margin: 90px 0;
    }

    .header .social-medias {
        justify-content: space-around;
        width: 100%;
        margin: 30px 0;
    }

    .header .social-medias a {
        font-size: 2.5rem;
    }

    .header .menu .nav > * > div {
        padding: 5px 10px;
        margin: 2vmin 1vmin;
    }
}