.flag-message {
    margin: 40px;
    display: flex;
    align-items: center;
    text-align: center;
    border-radius: 1000px;
    font-size: 1.2rem;
    padding: 0.8em 1em;
    gap: 10px;
}

.flag-message.outdated {
    background-color: var(--accent-yellow);
}

.flag-message.preview {
    background-color: var(--accent-blue);
}

.flag-message svg {
    font-size: 2.4rem;
}

@media screen and (max-width: 1000px) {
    .flag-message {
        margin: 40px 0 0;
        flex-direction: column;
        border-radius: 45px;
    }
}