.project-card-carousel {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-card-carousel .title {
    position: absolute;
    top: 30px;
    font-weight: 700;
    font-size: 1.5rem;
    padding: 0.2em 0.5em;
    z-index: 20;
    border-radius: 1000px;
    background-color: var(--accent-black);
}

.project-card-carousel .embedded-project-card {
    position: absolute;
    visibility: hidden;
    transition: 0.4s ease;
    z-index: 5;
}

.project-card-carousel .down-arrow {
    font-size: 4vh;
    position: absolute;
    transform: translateY(200px);
    z-index: 8;
    pointer-events: none;
    transition: 0.4s ease;
}

.project-card-carousel .up-arrow {
    font-size: 4vh;
    position: absolute;
    transform: translateY(-200px);
    z-index: 8;
    pointer-events: none;
    transition: 0.4s ease;
}

.project-card-carousel .embedded-project-card.selected + .down-arrow {
    transform: translateY(0px);
}

.project-card-carousel .up-arrow:has(+ .embedded-project-card.selected) {
    transform: rotate(0.5turn) translateY(0px);
}

.project-card-carousel .embedded-project-card:has(+ .embedded-project-card.selected) {
    transform: scale(0.8) translateY(-40%);
    visibility: visible;
    filter: brightness(0.5);
    z-index: 5;
}

.project-card-carousel .embedded-project-card.selected {
    position: relative;
    visibility: visible;
    z-index: 10;
}

.project-card-carousel .embedded-project-card.selected + * {
    transform: scale(0.8) translateY(40%);
    visibility: visible;
    filter: brightness(0.5);
    z-index: 5;
}

@media screen and (max-width: 1000px) {
    .project-card-carousel {
        height: 600px;
    }
}