.header-project-card {
    background-color: var(--accent-black);
    border-radius: 45px;
    display: flex;
    overflow: hidden;
    text-align: center;
}

.header-project-card .contributors {
    background-color: var(--normal-white);
    color: var(--accent-black);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 30px;
    gap: 20px;
    width: 50%;
    box-sizing: border-box;
}

.header-project-card .downloads {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 50px 30px;
    gap: 20px;
    box-sizing: border-box;
}

.header-project-card .downloads .title {
    font-size: 3rem;
    font-weight: 700;
}

.header-project-card .contributors .title {
    font-size: 2rem;
    font-weight: 700;
}

.header-project-card .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
}

@media screen and (max-width: 1000px) {
    .header-project-card {
        flex-direction: column;
    }

    .header-project-card .contributors, .header-project-card .downloads {
        width: 100%;
    }

    .header-project-card .buttons {
        flex-direction: column;
    }
}